import oneConfig from '@parkmobile/one-config';
import React from 'react';
import { PageMetadata } from '@/app/components';
import { StartView } from '@/views/global-onstreet-parking/components';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../_error';

const config = oneConfig.config();
const isEasyPark = config.IS_EASYPARK;

StartPage.config = {
  layout: 'fluid',
};

function StartPage() {
  const { t } = useTranslation();
  const flags = useSelector(getAllFlags);
  const isGlobalOnStreetEnabled =
    FeatureFlags.isGlobalOnStreetParkingEnabled(flags);
  const isEnabled = isEasyPark && isGlobalOnStreetEnabled;

  return isEnabled ? (
    <>
      <PageMetadata
        description={t('global:metadata:start.description')}
        key='start-metadata'
        title={[
          t('global:metadata:start.title1'),
          t('global:metadata:start.title2'),
        ]}
      />
      <StartView key='start-view' />
    </>
  ) : (
    <ErrorPage statusCode={404} />
  );
}

export default StartPage;
