
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/global/start",
      function () {
        return require("private-next-pages/global/start.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/global/start"])
      });
    }
  